.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background: #ffd632;
    color: #000;
    font-family: 'Roboto', sans-serif;
    padding: 20px;
  }
  
  .title {
    font-size: 3rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #ff0066;
    background: #000;
    padding: 10px 20px;
    border: 5px solid #ff0066;
    box-shadow: 10px 10px 0 #000;
  }
  
  .info-card {
    background: #fff;
    color: #000;
    padding: 20px;
    border: 5px solid #000;
    box-shadow: 10px 10px 0 #000;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .info-card h2 {
    margin: 0 0 10px 0;
    font-size: 2rem;
    color: #ff0066;
  }
  
  .info-card p {
    margin: 0;
    font-size: 1rem;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 400px;
    background: #00ccff;
    padding: 20px;
    border: 5px solid #000;
    box-shadow: 10px 10px 0 #000;
  }
  
  .input, .select {
    padding: 0.75rem;
    border: 3px solid #000;
    border-radius: 0;
    background-color: #fff;
    color: #000;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .input:focus, .select:focus {
    outline: none;
    box-shadow: 0 0 0 3px #ff0066;
  }
  
  .button {
    padding: 0.75rem;
    border: 3px solid #000;
    border-radius: 0;
    background-color: #ff0066;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .button:hover {
    background-color: #ff3399;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .qr-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
  
  .qr-code-image {
    max-width: 100%;
    border: 5px solid #000;
    box-shadow: 10px 10px 0 #000;
  }
  
  .download-link {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #ff0066;
    color: #fff;
    text-decoration: none;
    border: 3px solid #000;
    font-weight: bold;
    transition: all 0.3s ease;
  }
  
  .download-link:hover {
    background-color: #ff3399;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .footer-card {
    background: #fff;
    color: #000;
    padding: 20px;
    border: 5px solid #000;
    box-shadow: 10px 10px 0 #000;
    margin-bottom: 2rem;
    text-align: center;
    margin-top: 2rem;
  }
  
  .footer-card h2 {
    margin: 0 0 10px 0;
    font-size: 1rem;
    color: #00ccff;
  }
  
  .footer-card p {
    margin: 0;
    font-size: 1rem;
  }

  .new-icon {
    width: 45px;
    height: 45px;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
    fill: #ffffff;
    animation: rotate 3s ease-in-out infinite, breathe 2s ease-in-out infinite, shimmer 3s linear infinite;
  }

  @keyframes rotate {
    0%, 100% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(5deg);
    }
    75% {
      transform: rotate(-5deg);
    }
  }

  @keyframes breathe {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }

  @keyframes shimmer {
    0% {
      filter: brightness(100%);
    }
    50% {
      filter: brightness(120%);
    }
    100% {
      filter: brightness(100%);
    }
  }
  
  .logo-upload label {
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  .logo-upload {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .logo-upload label {
    font-weight: bold;
  }

  .logo-upload input[type="file"] {
    border: 2px solid #000;
    padding: 0.5rem;
    background-color: #fff;
  }

.generate-site-button {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 24px;
  background: linear-gradient(90deg, #8b5cf6, #6366f1);
  color: white;
  text-decoration: none;
  border-radius: 50px;
  font-weight: bold;
  font-size: 18px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.generate-site-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.button-content {
  position: relative;
  z-index: 1;
}

.generate-site-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(45deg);
  animation: shine 3s infinite;
}

@keyframes shine {
  0% {
    top: -50%;
    left: -50%;
  }
  100% {
    top: 150%;
    left: 150%;
  }
}
